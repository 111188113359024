import { combineReducers } from "redux";
import currentLoginUserPayload from "./current-login-user/Reducer";
import fetchDashboardDataPayload from "./dashboard/Reducer";
import findingsDataPayload from "./findings/Reducer";
import fetchScanCancelPayload from "./scannings/cancel-scan/Reducer";
import fetchScanningsDataPayload from "./scannings/fetch-scannings/Reducer";
import fetchScanLaunchPayload from "./scannings/launch-scan/Reducer";
import fetchsaveScheduledScansToDBPayload from "./scannings/save-scheduled-scans-to-db/Reducer";
import fetchSettingsDataPayload from "./settings-page/Reducer";
import settings from "./settings/Reducer";
import fetchTargetDetailsDataPayload from "./target-details/Reducer";
import userDeleteStatusPayload from "./user-setting/delete-detail/Reducer";
import detailPageModePayload from "./user-setting/detail-page-mode/Reducer";
import editUserFlagPayload from "./user-setting/edit-flag/Reducer";
import currentUserDetailPayload from "./user-setting/fetch-detail/Reducer";
import usersPayload from "./user-setting/fetch-list/Reducer";
import userInsertStatusPayload from "./user-setting/insert-detail/Reducer";
import resetPasswordPageLoadStatusPayload from "./user-setting/load-password-reset-page/Reducer";
import newUserFlagPayload from "./user-setting/new-flag/Reducer";
import currentUserIdPayload from "./user-setting/update-current-user-id/Reducer";
import userUpdateStatusPayload from "./user-setting/update-detail/Reducer";
import { accountTargetDetailPageModePayload, targetDetailPageModePayload } from "./target-setting/detail-page-mode/Reducer";
import {
    accountSettingPageMessageBoxStatusPayload,
    accountSettingPageStatusPayload,
    accountSettingPayload,
    targetDetailPageState
} from "./account-setting/Reducer";
import editBillingSettingFlagPayload from "./account-setting/edit-billing-flag/Reducer";
import editGeneralAccountSettingFlagPayload from "./account-setting/edit-general-flag/Reducer";
import editAccountTimeZoneSettingFlagPayload from "./account-setting/edit-time-zone-flag/Reducer";
import accountSettingUpdateStatusPayload from "./account-setting/update/Reducer";
import accountSubscriptionPayload from "./account-subscription/Reducer";
import billingPayload from "./billing/Reducer";
import assigneeUpdateStatusPayload from "./issue-details/update-assignee/Reducer";
import feedbackUpdateStatusPayload from "./issue-details/update-feedback/Reducer";
import notificationsPayload from "./notifications/fetch-notifications/Reducer";
import saveNotificationsReadStatusPayload from "./notifications/save-notifications-read-status/Reducer";
import scanQueueSettingPayload from "./queue-setting/fetch-queue-setting/Reducer";
import updateScanQueueSettingPayload from "./queue-setting/update/Reducer";
import { stripeCheckout } from "./stripe/Reducers";
import { targetActivationModeStatusPayload, targetDeactivationModeStatusPayload } from "./target-setting/Reducers";
import targetActivationStatusPayload from "./target-setting/activation/Reducer";
import targetDeactivationStatusPayload from "./target-setting/deactivation/Reducer";
import targetSettingDeleteStatusPayload from "./target-setting/delete/Reducer";
import editTargetAuthenticationFlagPayload from "./target-setting/edit-authentication-flag/Reducer";
import editTargetSettingFlagPayload from "./target-setting/edit-flag/Reducer";
import editTargetScanConfigFlagPayload from "./target-setting/edit-scan-config-flag/Reducer";
import { currentAccountTargetSettingPayload, currentTargetSettingPayload } from "./target-setting/fetch-detail/Reducer";
import { accountTargetSettingsPayload, targetSettingsPayload } from "./target-setting/fetch-list/Reducer";
import { targetAuthenticationTestCancellationSubmissionPayload, targetAuthenticationTestResultPayload, targetAuthenticationTestSubmissionPayload } from "./target-setting/test-authentication/Reducer";
import targetSettingUpdateStatusPayload from "./target-setting/update/Reducer";
import { latestTosReducer, tosAcceptanceSubmissionReducer, tosReducer } from "./tos/Reducers";
import { userAccountActivationDetailReducer } from "./user-account-activation/Reducers";
import invitationDeliveryStatusPayload from "./user-setting/send-invitation/Reducer";
import { accountSubscriptionCancellationStatusPayload, publicPlansPayload } from "./wix/Reducers";

export default combineReducers({
    settings,
    usersPayload,
    userInsertStatusPayload,
    userUpdateStatusPayload,
    userDeleteStatusPayload,
    editUserFlagPayload,
    fetchDashboardDataPayload,
    newUserFlagPayload,
    currentUserDetailPayload,
    currentUserIdPayload,
    resetPasswordPageLoadStatusPayload,
    detailPageModePayload,
    //fetchWikiDataPayload,
    fetchScanningsDataPayload,
    fetchScanLaunchPayload,
    fetchScanCancelPayload,
    fetchsaveScheduledScansToDBPayload,
    findingsDataPayload,
    //fetchFindingDetailsDataPayload,
    fetchTargetDetailsDataPayload,
    fetchSettingsDataPayload,
    currentLoginUserPayload,
    //targetInsertStatusPayload,
    //newTargetFlagPayload,
    targetDetailPageModePayload,
    accountTargetDetailPageModePayload,
    currentTargetSettingPayload,
    currentAccountTargetSettingPayload,
    targetSettingsPayload,
    accountTargetSettingsPayload,
    targetSettingUpdateStatusPayload,
    targetSettingDeleteStatusPayload,
    editTargetSettingFlagPayload,
    editTargetScanConfigFlagPayload,
    editTargetAuthenticationFlagPayload,
    targetAuthenticationTestSubmissionPayload,
    targetAuthenticationTestResultPayload,
    targetAuthenticationTestCancellationSubmissionPayload,
    assigneeUpdateStatusPayload,
    feedbackUpdateStatusPayload,
    accountSettingPayload,
    accountSettingPageStatusPayload,
    accountSettingPageMessageBoxStatusPayload,
    targetDetailPageState,
    accountSettingUpdateStatusPayload,
    editAccountTimeZoneSettingFlagPayload,
    editGeneralAccountSettingFlagPayload,
    editBillingSettingFlagPayload,
    
    targetDeactivationModeStatusPayload,
    targetActivationModeStatusPayload,
    targetDeactivationStatusPayload,
    targetActivationStatusPayload,
    publicPlansPayload,
    accountSubscriptionCancellationStatusPayload,

    billingPayload,
    notificationsPayload,
    saveNotificationsReadStatusPayload,
    scanQueueSettingPayload,
    updateScanQueueSettingPayload,

    stripeCheckout,
    latestTosReducer,
    tosAcceptanceSubmissionReducer,
    tosReducer,
    userAccountActivationDetailReducer,

    accountSubscriptionPayload,
    invitationDeliveryStatusPayload
});